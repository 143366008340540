import * as React from "react"

import Logo from "../components/atoms/Logo";
import Seo from "../components/seo";
import Mountains from "../components/atoms/Mountains";
import ErrorCode from "../components/molecules/ErrorCode";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <main className="center-flex">
      <div className="container">
        <Logo link={false} classes="logo--large" />
        <ErrorCode number="404" />
        <Mountains />
      </div>
    </main>
  </>
)

export default NotFoundPage
